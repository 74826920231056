<template>
    <Toast position="bottom-right"/>
    <ConfirmDialog></ConfirmDialog>
    <div class="main-container">
        <div v-if="this.status == 'unauthenticated'" class="loginbox">
        <form class="loginform">
            <h2>Login</h2>
            <br>
            <span class="p-float-label">
                <InputText style="padding:5px; padding-left: 10px" type="text" placeholder="Username" v-model="name" />
            </span>
            <br>
            <span class="p-float-label">
                <InputText style="padding:5px; padding-left: 10px" type="password" placeholder="Password" v-model="password" />
                <p style="color:red; font-size: 10px">{{serverMessage}}</p>
            </span>
            <br>
            <Button style="padding: 5px; padding-left: 20px; padding-right: 20px;" label="Login" class="p-button-success" @click.prevent="login"/>
        </form>
        </div>
        <div v-else class="container-fluid">
            <Button style="float:right" type="button" icon="pi pi-bars" @click="toggle" />
            <Menu ref="menu" :model="items" :popup="true" />
            <div v-if="contentState === 'home'" class="imgbox">
                <img class="center-fit" src="/images/CBLogo.png">

                <br>
                <iframe width="560" height="315" src="https://www.youtube.com/embed/L7F2RES4RQg?si=V_AM1IL1b9z593p4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                <br>

                <p>Version 1.8</p>
                <div class="buttonbox">
                    <a href="download/v_1.8/codebook.apk" target="_blank">
                        <Button style="padding: 30px" label="Android" icon="pi pi-download" class="p-button-success"/>
                    </a>
                    <a href="download/v_1.8/codebook_windows.zip" target="_blank">
                        <Button style="padding: 30px" label="Windows" icon="pi pi-download" class="p-button-success"/>
                    </a>
                    <a href="download/v_1.8/mac.zip" target="_blank">
                        <Button style="padding: 30px" label="Mac" icon="pi pi-download" class="p-button-success"/>
                    </a>
                    <a href="download/v_1.2/Codebook_Usermanual.pdf" target="_blank">
                        <Button style="padding: 30px" label="Dokumentation" icon="pi pi-download" class="p-button-success"/>
                    </a>
                </div>

                <p>Legacy (Nur mit altem Server!)</p>
                <div class="buttonbox">
                    <a href="download/codebook_old.apk" target="_blank">
                        <Button style="padding: 30px" label="Android" icon="pi pi-download" class="p-button-success"/>
                    </a>
                    <a href="download/codebook.zip" target="_blank">
                        <Button style="padding: 30px" label="Windows" icon="pi pi-download" class="p-button-success"/>
                    </a>
                    <a href="download/codebook.dmg" target="_blank">
                        <Button style="padding: 30px" label="Mac" icon="pi pi-download" class="p-button-success"/>
                    </a>
                </div>

            </div>
            <TDSCSession v-if="contentState === 'session'"></TDSCSession>
            <ScenarioEditor v-if="contentState === 'scenarioEditor'"></ScenarioEditor>
            <HospitalEditor v-if="contentState === 'layout'"></HospitalEditor>
            <Reports v-if="contentState === 'reports'"></Reports>
        </div>
    </div>
</template>

<script>
import TDSCSession from "./Session/tdscSession";
import ScenarioEditor from "./Scenario/scenarioEditor";
import HospitalEditor from "./Layout/hospitalEditor";
import Reports from "./BugReport/BugReports"

export default {
    components:{TDSCSession, ScenarioEditor, HospitalEditor, Reports},
    data() {
        return {
            status: "unauthenticated",
            contentState: "home",
            serverMessage: "",
            items: [
                {
                    label: 'Home',
                    icon: 'pi pi-home',
                    command: () => {
                        this.contentState = "home";
                    }
                },
                {
                    label: 'Sessions',
                    icon: 'pi pi-table',
                    command: () => {
                        this.contentState = "session";
                    }
                },
                {
                    label: 'Szenario-Editor',
                    icon: 'pi pi-sitemap',
                    command: () => {
                        this.contentState = "scenarioEditor";
                    },
                },
                {
                    label: 'Krankenhaus-Editor',
                    icon: 'pi pi-sitemap',
                    command: () => {
                        this.contentState = "layout";
                    },
                },
                {
                    label: 'Reports',
                    icon: 'pi pi-database',
                    command: () => {
                        this.contentState = "reports";
                    },
                    visible: () => sessionStorage.getItem('role') === "admin"
                }
            ],
        }
    },
    methods: {
        toggle(event) {
            this.$refs.menu.toggle(event);
        },
        async login() {
            try {
                axios.get('/sanctum/csrf-cookie').then(response => {
                    axios.post('/login', {
                        name: this.name,
                        password: this.password,
                    }).then(response => {
                        window.axios.defaults.headers.Authorization = `Bearer ${response['data'].token}`;
                        axios.get('/api/codebook/user').then(response => {
                            sessionStorage.setItem('username', response.data["name"]);
                            sessionStorage.setItem('role', response.data["role"]);
                            this.status = "authenticated"
                        });
                    }).catch(error => {
                        if (error.response.status === 429)
                            this.serverMessage = "Anmeldelimit erreicht, warten!";
                        else if (error.response.status === 401)
                            this.serverMessage = "Falscher Nutzername oder Passwort!";

                    });
                }).catch(error => {
                    this.serverMessage = error.response.data["error"];
                });
            } catch (error) {
            }
        },
    },
    beforeMount() {
        sessionStorage.clear();
    }
}
</script>

<style scoped>

.main-container {
    padding:30px;
}

* {
    margin: 0;
    padding: 0;
}
.imgbox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.center-fit {
    max-width: 100%;
    max-height: 70vh;
    margin: auto;
}

.buttonbox {
    display: flex;
    justify-content: center;
    align-items: center;
}


.loginform {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.buttonbox > * {
    margin: 10px;
}
</style>
